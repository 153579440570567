/** @jsx jsx */
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { Box, Grid, jsx } from 'theme-ui'
import IncludedProduct from '~/components/Kit/IncludedProduct'
import { objectCompare } from '~/utils/compare'

const IncludedProducts = ({ products = [], handleProductChange }) => {
  const [currentSelection, setCurrentSelection] = useState(
    products.map(p => ({
      slug: p.slug,
      sku: p.variants[0].sku
    }))
  )

  const onVariantChange = useCallback(
    (product, variant) => {
      const selection = { slug: product.slug, sku: variant.sku }

      const newSelection = currentSelection.map(x =>
        selection.slug === x.slug ? selection : x
      )

      if (!objectCompare(currentSelection, newSelection)) {
        setCurrentSelection(newSelection)
        handleProductChange(
          newSelection
            .map(({ sku }) => sku)
            .sort()
            .join('|')
        )
      }
    },
    [handleProductChange, currentSelection]
  )

  return (
    <Box>
      <Grid gap={[2, 3]} columns={1}>
        {products.map((product, index) => (
          <IncludedProduct
            key={index}
            product={product}
            handleVariantChange={onVariantChange}
          />
        ))}
      </Grid>
    </Box>
  )
}

IncludedProducts.propTypes = {
  products: PropTypes.array,
  handleProductChange: PropTypes.func.isRequired
}

export default IncludedProducts
