/** @jsx jsx */
import { useAnalytics } from '@arfabrands/gatsby-theme-assembly'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import ProductOptions from '~/components/Product/Options'
import useProductVariants from '~/hooks/components/use-product-variants'

const IncludedProduct = ({ product, handleVariantChange }) => {
  const { trackClickProduct } = useAnalytics()

  const { slug, variants, optionTypes } = product

  const { currentVariant, selectVariant } = useProductVariants(variants)

  const { name, mainImage, size } = currentVariant

  useEffect(() => {
    handleVariantChange(product, currentVariant)
  }, [handleVariantChange, product, currentVariant])

  return (
    <Flex
      key={slug}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        margin: 0,
        height: '100%'
      }}
    >
      <Link to={`/products/${slug}/`} onClick={() => trackClickProduct(slug)}>
        <Img
          fluid={mainImage.fluid}
          alt={mainImage.title}
          key={mainImage.title}
          sx={{ minWidth: '120px', height: '100%' }}
        />
      </Link>

      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column'],
          alignItems: 'flex-start',
          margin: '0 1.5rem',
          textAlign: 'left',
        }}
      >
        <Link
          sx={{
            display: 'block',
            textTransform: 'none',
            variant: ['text.h4']
          }}
          to={`/products/${slug}/`}
        >
          <Text sx={{
            fontFamily: '"GT Walsheim Bold", Helvetica, Arial, sans-serif',
            fontSize: '13px',
            marginTop: '0.5rem',
            textDecoration: 'underline',
          }}>
            {name}
          </Text>
          <Text sx={{
            fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
            fontWeight: 'normal',
            marginTop: '0.5rem'
          }}>
            {size}
          </Text>
        </Link>

        {optionTypes && (
          <ProductOptions
            allOptions={optionTypes}
            currentValues={currentVariant.optionValues}
            handleChange={selectVariant}
          />
        )}
      </Flex>
    </Flex>
  )
}

IncludedProduct.propTypes = {
  product: PropTypes.shape({}).isRequired,
  handleVariantChange: PropTypes.func.isRequired
}

export default IncludedProduct
