import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import KitPage from '~/components/Kit/Page'

const constants = {
  backgroundColor: 'rgb(145, 211, 202)',
  navTextColor: '#005D5F',
  logoColor: '#005D5F',
}

const KitTemplate = ({ data }) => {
  const { kit } = data

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
      logoColor={constants.logoColor}
    >
      <Metadata title={kit.name} description={kit.shortDescription} />
      <KitPage kit={kit} />
    </Layout>
  )
}

export const query = graphql`
  query KitQuery($slug: String, $locale: String) {
    kit: contentfulKit(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...KitPageFragment
    }
  }
`

export default KitTemplate
