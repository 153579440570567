/** @jsx jsx */
import { utils } from '@arfabrands/gatsby-theme-assembly'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'
import IncludedProducts from '~/components/Kit/IncludedProducts'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductIngredients from '~/components/Product/Ingredients'
import ProductOutOfStock from '~/components/Product/OutOfStock'
import ProductQuantitySelector from '~/components/Product/QuantitySelector'

const KitDetails = ({ kit, ...props }) => {
  const { toUsdCurrency } = utils

  const {
    name,
    description,
    price,
    regularPrice,
    comingSoon,
    soldOut,
    ingredients = null,
    products
  } = kit

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  // Initialize the current sku with the first variant available
  // for each product in the kit
  const [currentSku, setCurrentSku] = useState(
    products
      .map(({ variants }) => variants[0].sku)
      .sort()
      .join('|')
  )

  return (
    <Box
      {...props}
      sx={{
        textAlign: ['center', null, 'left'],
        margin: 'auto',
        marginTop: ['8px', null],
        maxWidth: ['650px !important', null, '50% !important']
      }}
    >
      <Heading
        as="h1"
        variant="h2"
        sx={{
          color: 'rgb(0, 93, 95)',
          fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
          fontWeight: 'normal',
          fontSize: ['34px', '55px'],
          marginBottom: '0.5rem',
          marginTop: ['20px', null, '0'],
          maxWidth: ['100%', null, '75%'],
          textAlign: 'left',
        }}
      >
        {name}
      </Heading>

      {regularPrice !== price ? (
        <Flex
          sx={{
            height: '100%',
            flexDirection: 'row',
            padding: '0 0 10px'
          }}
        >
          <Text sx={{ lineHeight: '1.5rem' }}>
            {toUsdCurrency(price)}
          </Text>
          <Text
            sx={{
              textDecoration: 'line-through',
              opacity: '.7',
              variant: 'text.formLabel',
              paddingLeft: '1rem'
            }}
          >
            {toUsdCurrency(regularPrice)}
          </Text>
        </Flex>
      ) : (
        <Text>{toUsdCurrency(price)}</Text>
      )}

      {products && (
        <IncludedProducts
          products={products}
          handleProductChange={setCurrentSku}
        />
      )}

      <Flex
        py="3"
        sx={{
          flexDirection: ['column', 'row'],
          width: ['100%', '70%'],
        }}
      >
        <ProductQuantitySelector
          handleChange={value => setSelectedQuantity(parseInt(value))}
        />
        <ProductAddToCartButton
          sx={{
            marginLeft: ['12px', null, '16px']
          }}
          soldOut={soldOut}
          comingSoon={comingSoon}
          price={price}
          quantity={selectedQuantity}
          regularPrice={regularPrice}
          sku={currentSku}
          pdp={true}
          kit={true}
        />
      </Flex>

      {(soldOut || comingSoon) && <ProductOutOfStock sku={currentSku} />}

      <div
        sx={{
          textAlign: 'left',
          '& ul': {
            padding: '0 0 0 1rem'
          },
          '& p': {
            marginTop: '8px',
            marginBottom: '28px'
          }
        }}
        dangerouslySetInnerHTML={{
          __html: description.childMarkdownRemark.html
        }}
      />

      {ingredients && <ProductIngredients titleColor={'rgb(0, 93, 95)'} ingredients={ingredients} />}
    </Box>
  )
}

KitDetails.propTypes = {
  kit: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    soldOut: PropTypes.bool,
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    ingredients: PropTypes.shape({})
  })
}

export default KitDetails
